var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mr-0 px-0" },
    [
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              items: _vm.data,
              headers: _vm.dataHeaders,
              search: _vm.nameSearchable,
            },
            scopedSlots: _vm._u([
              {
                key: "item.Index",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                  ]
                },
              },
              {
                key: "item.created_at",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(item.created_at.split("T")[0]) + " "),
                  ]
                },
              },
              {
                key: "item.name",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm.allowedRolesRedirectCompanies.some(function (role) {
                      return _vm.$store.getters.getRole.includes(role)
                    })
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "PageCompaniesDetail",
                                params: {
                                  id: _vm.data[_vm.data.indexOf(item)]
                                    .companies_id,
                                },
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      : _c("span", [_vm._v(" " + _vm._s(item.name) + " ")]),
                  ]
                },
              },
              {
                key: "top",
                fn: function () {
                  return [
                    _c(
                      "v-toolbar",
                      {
                        attrs: {
                          width: "auto",
                          height: "auto",
                          color: " py-3",
                          flat: "",
                        },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { staticClass: "mt-3 mx-3" },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mb-3" },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass: "font-weight-bold text-h4",
                                      },
                                      [_vm._v(" Person-in-charge ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-divider"),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        clearable: "",
                                        label: "Search",
                                      },
                                      model: {
                                        value: _vm.nameSearchable,
                                        callback: function ($$v) {
                                          _vm.nameSearchable = $$v
                                        },
                                        expression: "nameSearchable",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.currentRole == "operation"
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: { click: _vm.redirectNew },
                                  },
                                  [_vm._v(" New ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }